import React, { Component } from "react";
import styled from "styled-components";
import { withPage } from "../../Page";
import team from "../../../content/team.json";
import * as Widget2 from "../../Components/Widget2";

class MedicalTeamPage extends Component {
  render() {
    return (
      <Wrapper>
        <Widget2.Banner img="/images/banner03.png" css="margin-bottom: 90px;">
          <div className="mist">
            <div className="title">專業照護</div>
            <div className="slogan">專業團隊，專業守護媽媽與寶寶</div>
          </div>
        </Widget2.Banner>

        <Container>
          <Content>
            {team.map((item, i) => (
              <ProfileItem
                key={"profile-item" + i}
                img={`/images/${item.image}`}
                css="margin-bottom: 48px;"
              >
                <div className="img" />
                <div className="text">
                  <div className="title">{item.name} 醫師</div>
                  <div className="position">
                    <span>現任職位</span>
                    <span>{item.position}</span>
                  </div>
                  <div className="intro">
                    <span>經歷</span>
                    <p>{item.experience}</p>
                  </div>
                </div>
              </ProfileItem>
            ))}
          </Content>
        </Container>
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  padding-top: 100px;
`;

const Container = styled.div`
  margin: 0 auto;
  max-width: 1181px;
  padding: 20px;
`;

const Content = styled.div`
  max-width: 780px;
  margin: 0 auto;
`;

const ProfileItem = styled.div`
  display: flex;
  align-items: center;

  & > .img {
    margin-right: 20px;
    max-width: 280px;
    min-height: 400px;
    width: 100%;
    height: 100%;
    background-image: url(${props => props.img});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    @media screen and (max-width: 414px) {
      margin-right: 0px;
      margin-bottom: 20px;
    }
  }

  & > .text {
    max-width: 490px;
    width: 100%;

    & > .title {
      margin-bottom: 31px;
      font-weight: bold;
      font-size: 30px;
      color: #71c6d0;
      letter-spacing: 3px;

      @media screen and (max-width: 600px) {
        font-size: 20px;
      }

      @media screen and (max-width: 414px) {
        margin-bottom: 20px;
        text-align: center;
      }
    }

    & > .position {
      margin-bottom: 31px;
      margin-left: 70px;
      font-size: 24px;
      color: rgba(16, 29, 31, 0.6);
      letter-spacing: 2.4px;

      & > span {
        margin-right: 27px;
      }

      @media screen and (max-width: 600px) {
        margin-left: 45px;
        font-size: 14px;
      }

      @media screen and (max-width: 414px) {
        margin-bottom: 20px;
        margin-left: 0px;
        text-align: center;
      }
    }

    & > .intro {
      margin-left: 70px;
      font-size: 24px;
      color: rgba(16, 29, 31, 0.6);
      letter-spacing: 2.4px;

      & > p {
        white-space: pre-wrap;
        font-size: 24px;
        color: #101d1f;
        letter-spacing: 2.4px;

        @media screen and (max-width: 600px) {
          font-size: 14px;
        }
      }

      @media screen and (max-width: 600px) {
        margin-left: 45px;
        font-size: 14px;
      }

      @media screen and (max-width: 414px) {
        margin-left: 0px;
        text-align: center;
      }
    }
  }

  @media screen and (max-width: 414px) {
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }

  ${props => props.css};
`;

export default withPage(MedicalTeamPage);
